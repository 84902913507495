import {
  ComplexQuestionTypesEnum,
  InterviewPublic,
  MultipleChoiceInterviewQuestionPublic,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, RadioButtonsGroup } from "ui";
import { ComplexAnswerPublic } from "../complexAnswers/complexAnswersSlice";
import { InterviewQuestionSection } from "../interview/interviewQuestionSection";

export interface SurveyQuestionBaseProps {
  answer: ComplexAnswerPublic;
  interview: InterviewPublic;
  onSaveComplexAnswer: (complexAnswer: ComplexAnswerPublic) => void;
}

const focusStyle = "focus:outline focus:outline-1 focus:outline-indigo-600";

export const MultipleChoiceQuestionSection: FC<
  SurveyQuestionBaseProps & { question: MultipleChoiceInterviewQuestionPublic }
> = ({ question, answer, interview, onSaveComplexAnswer }) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  if (question.type !== ComplexQuestionTypesEnum.MULTIPLE_CHOICE) {
    return null;
  }

  return (
    <div className="flex flex-col items-center min-h-full sm:min-h-0">
      <div className="box-border pt-6 pl-6 pr-6 w-full">
        <InterviewQuestionSection
          fragmentOrSurveyQuestion={question}
          interview={interview}
        />
        <div className="space-y-4 my-4">
          <div className={focusStyle}>
            <RadioButtonsGroup
              options={question.options}
              value={selectedValue}
              onChange={(value) => {
                setShouldShowError(false);
                setSelectedValue(value);
              }}
            />
          </div>
          {shouldShowError ? (
            <div className="text-red-900 text-sm">
              Please select an option to continue.
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-end p-3 w-full border-t border-gray-200 space-x-2">
        <Button
          onClick={() => {
            // Validate that a choice has been made
            if (!selectedValue) {
              setShouldShowError(true);
              return;
            }

            onSaveComplexAnswer({
              ...answer,
              response: {
                value: selectedValue,
              },
            });
          }}
          variant={ButtonVariantsEnum.Primary}
          label="Continue"
        />
      </div>
    </div>
  );
};
