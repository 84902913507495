import {
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
} from "../../index.ts";
import { Tables, TablesInsert, TablesUpdate } from "../db/supabase.ts";
import { CustomFieldTypesEnum } from "./customField.ts";

export type AssessmentDB = Tables<"assessment">;
export type AssessmentUpdate = TablesUpdate<"assessment">;

export enum SpecialAssessmentFieldNamesEnum {
  CEFR_LANGUAGE_LEVEL_ENGLISH = "cefr_language_level_english",
}

export const CANDIDATE_ALIGN_FIELD_NAME =
  "Candidate's preferences and working style are aligned with the job";

export const SpecialAssessmentFieldDetails = {
  [SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH]: {
    display_name: "CEFR English level estimate",
    field_type: CustomFieldTypesEnum.TEXT,
  },
};

export const isSpecialAssessmentFieldName = (fieldName: string) => {
  return Object.values(SpecialAssessmentFieldNamesEnum).includes(
    fieldName as SpecialAssessmentFieldNamesEnum
  );
};

export const getSpecialAssessmentField = (
  customFieldDefinitions: (
    | CustomFieldDefinition
    | CustomFieldDefinitionInsertClient
  )[],
  specialFieldName: SpecialAssessmentFieldNamesEnum
) => {
  return customFieldDefinitions.find((d) => d.field_name === specialFieldName);
};

export type AssessmentHasCustomFieldDefinitionDB =
  Tables<"assessment_custom_field_definition">;
export type AssessmentHasCustomFieldDefinitionInsert =
  TablesInsert<"assessment_custom_field_definition">;

export interface Assessment {
  id: string;
  custom_field_definitions: CustomFieldDefinition[];
}

export function mapAssessmentDBToAssessment(
  assessmentDB: AssessmentDB,
  customFieldDefinitions: CustomFieldDefinition[]
) {
  return {
    id: assessmentDB.id,
    custom_field_definitions: customFieldDefinitions,
  };
}

// Sorts assessment field so that special fields are displayed first
export function sortAssessmentFields(fields: CustomFieldDefinition[]) {
  return [...fields].sort((a, b) => {
    return isSpecialAssessmentFieldName(a.field_name) ? -1 : 1;
  });
}
