import { FC, useEffect, useState } from "react";
import { formatDuration } from "../../utils/formatDuration";

// Displays a ticker like "0:06" that updates every second
export const CallTicker: FC = () => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDuration((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="text-xl font-semibold">{formatDuration(duration)}</div>
  );
};
