import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import App from "./app/app";
import store from "./app/store";
import "./index.css";

Sentry.init({
  // Only enabled in production because VITE_SENTRY_INTERVIEW_DSN will only exist in prod.
  dsn: import.meta.env.VITE_SENTRY_INTERVIEW_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/api\.alpharun\.com/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: import.meta.env.VITE_IS_PRODUCTION
    ? "production"
    : "development",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Disable auto-recording and session recording until we know this is not a sensitive data company
posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: "https://us.posthog.com",
  autocapture: false,
  disable_session_recording: true,
});

const recaptchaPublicSiteKey =
  import.meta.env.VITE_RECAPTCHA_V3_SITE_KEY ||
  "6LcwlSgpAAAAADcW51ic1YKdFYjyh4E80-DAOdHA";

const el = document.getElementById("root");
if (el) {
  const root = createRoot(el);

  const app = (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={recaptchaPublicSiteKey}
        useRecaptchaNet={true}
        useEnterprise={true}
      >
        <App />
      </GoogleReCaptchaProvider>
    </Provider>
  );

  root.render(
    import.meta.env.VITE_IS_PRODUCTION ? (
      app
    ) : (
      <React.StrictMode>{app}</React.StrictMode>
    )
  );
} else {
  throw new Error("Could not find root element");
}
