import { FC, useCallback, useEffect } from "react";
import { Button, ButtonVariantsEnum, Loader, LoaderStylesEnum } from "ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  saveComplexAnswer,
  selectIsSavingComplexAnswers,
  selectUnsavedComplexAnswers,
} from "../complexAnswers/complexAnswersSlice";
import {
  postTranscriptFragment,
  selectIsSavingTranscriptFragments,
  selectUnsavedTranscriptFragments,
} from "../transcriptFragments/transcriptFragmentsSlice";

interface SaveRetryProps {
  isBackgroundMode: boolean;
  latestTranscriptFragmentId?: string;
  redirectUrl?: string;
}

export const SaveRetry: FC<SaveRetryProps> = ({
  latestTranscriptFragmentId,
  isBackgroundMode,
  redirectUrl,
}) => {
  const dispatch = useAppDispatch();
  const unsavedTranscriptFragments = useAppSelector(
    selectUnsavedTranscriptFragments
  );
  const unsavedComplexAnswers = useAppSelector(selectUnsavedComplexAnswers);
  const isSavingTranscriptFragments = useAppSelector(
    selectIsSavingTranscriptFragments
  );
  const isSavingComplexAnswers = useAppSelector(selectIsSavingComplexAnswers);

  const retrySave = useCallback(() => {
    unsavedTranscriptFragments.forEach((tf) =>
      dispatch(postTranscriptFragment({ id: tf.id }))
    );
    unsavedComplexAnswers.forEach((ca) => dispatch(saveComplexAnswer(ca)));
  }, [unsavedTranscriptFragments, unsavedComplexAnswers, dispatch]);

  // Whenever a new transcript fragment is added, also retry saving any unsaved fragments
  useEffect(() => {
    if (
      unsavedTranscriptFragments.length > 0 ||
      unsavedComplexAnswers.length > 0
    ) {
      retrySave();
    }
  }, [latestTranscriptFragmentId]);

  // If we're on the completed interview screen, redirect to the redirectUrl when all saving is done
  useEffect(() => {
    if (
      !isBackgroundMode &&
      redirectUrl &&
      unsavedTranscriptFragments.length === 0 &&
      unsavedComplexAnswers.length === 0 &&
      !isSavingTranscriptFragments &&
      !isSavingComplexAnswers
    ) {
      window.location.href = redirectUrl;
    }
  }, [
    isBackgroundMode,
    redirectUrl,
    unsavedTranscriptFragments,
    unsavedComplexAnswers,
    isSavingTranscriptFragments,
    isSavingComplexAnswers,
  ]);

  // In background mode (interview recorder), we don't render anything
  // In non-background mode (completion screen), we show a loading animation and explanation copy
  if (isBackgroundMode) return null;

  if (isSavingTranscriptFragments || isSavingComplexAnswers)
    return (
      <div className="text-sm mb-6 flex flex-col space-y-4 items-center">
        <div>
          {`Due to slow network speed, we're still saving your responses. Please keep this window open.`}
        </div>
        <Loader style={LoaderStylesEnum.ZOOMIES} />
      </div>
    );

  if (
    unsavedTranscriptFragments.length === 0 &&
    unsavedComplexAnswers.length === 0
  )
    return null;

  return (
    <div className="text-sm mb-6 flex flex-col space-y-4 items-center">
      <div>
        {`We're having trouble saving your responses. Please check your internet connection and click retry below.`}
      </div>
      <Button onClick={retrySave} variant={ButtonVariantsEnum.Primary}>
        Retry saving
      </Button>
    </div>
  );
};
