import { HandlerOf } from "app-types";
import { FC } from "react";
import { Select } from "ui";

interface MicrophoneSelectProps {
  microphones: MediaDeviceInfo[];
  setMicrophones: HandlerOf<MediaDeviceInfo[]>;
  onSelectMicrophone: (microphoneId: string) => void;
  setMicrophoneError: HandlerOf<Error | null>;
  currentMicrophoneDeviceId?: string;
  showLabel?: boolean;
  fetchMicrophones: () => void;
}

export const MicrophoneSelect: FC<MicrophoneSelectProps> = ({
  microphones,
  onSelectMicrophone,
  currentMicrophoneDeviceId,
  showLabel,
  fetchMicrophones,
}) => {
  // Use the selected microphone or default microphone
  const currentMic =
    microphones.find((m) => m.deviceId === currentMicrophoneDeviceId) ||
    microphones.find((m) => m.deviceId === "default") || // Chrome-only "default" option
    (microphones.length > 0 && microphones[0]);

  return (
    <Select
      label={showLabel ? "Microphone" : undefined}
      options={microphones.map((m) => {
        return {
          name: m.label,
        };
      })}
      onChange={(value) => {
        const mic = microphones.find((m) => m.label === value.name);
        if (mic) onSelectMicrophone(mic.deviceId);
      }}
      // Fallback to an empty selection to avoid a "A component
      // is changing from uncontrolled to controlled" warning
      currentSelection={currentMic ? { name: currentMic.label } : { name: "" }}
      onClick={() => {
        // Reload the microphones on open/close
        fetchMicrophones();
      }}
    />
  );
};
