import {
  EntityState,
  PayloadAction,
  Selector,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  ComplexAnswerPublicJSON,
  ComplexAnswerResponse,
  TranscriptFragmentSaveStatusEnum as SaveStatusEnum,
} from "app-types";
import { v4 as uuidv4 } from "uuid";
import { createAxiosInstance } from "../../api/axiosConfig";
import { RootState } from "../../app/store";
import {
  initiateInterviewForProjectLink,
  validateProjectLink,
} from "../projectLink/projectLinkSlice";
import { getInterviewData } from "../transcriptFragments/transcriptFragmentsSlice";

/*
 * State
 */

export type ComplexAnswerPublic = {
  id: string;
  question_id: string;
  response: ComplexAnswerResponse;
  save_status: SaveStatusEnum;
  error: string | null;
};

export const complexAnswersAdapter = createEntityAdapter<ComplexAnswerPublic>();

const initialState: EntityState<ComplexAnswerPublic> & {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
} = complexAnswersAdapter.getInitialState({
  status: "idle",
  error: null,
});

/*
 * Async actions
 */

export const saveComplexAnswer = createAsyncThunk<
  void,
  ComplexAnswerPublic,
  { rejectValue: string }
>("complexAnswers/save", async (payload, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;
  const token = rootState.transcriptFragments.token;

  try {
    const axiosInstance = createAxiosInstance(token, {
      enableRetries: true,
      enforceTimeout: true,
    });

    await axiosInstance.post("/interview/complex-answer", {
      id: payload.id,
      question_id: payload.question_id,
      response: payload.response,
    });
  } catch (error: any) {
    // Handle other error cases
    return thunkAPI.rejectWithValue(error.message);
  }
});

/*
 * Slice.
 */

const complexAnswersSlice = createSlice({
  name: "complexAnswers",
  initialState,
  reducers: {
    complexAnswerAdded: complexAnswersAdapter.addOne,
    complexAnswerUpdated: complexAnswersAdapter.updateOne,
    setAllComplexAnswers: complexAnswersAdapter.setAll,
    addComplexAnswerForQuestion: (
      state,
      action: PayloadAction<{ questionId: string }>
    ) => {
      const hasExistingAnswer = state.ids.some(
        (id) => state.entities[id]?.question_id === action.payload.questionId
      );

      if (!hasExistingAnswer) {
        complexAnswersAdapter.addOne(state, {
          id: uuidv4(),
          question_id: action.payload.questionId,
          response: [],
          save_status: SaveStatusEnum.IDLE,
          error: null,
        });
      }
    },
  },
  extraReducers: (builder) => {
    const setComplexAnswers = (
      state: EntityState<ComplexAnswerPublic>,
      complexAnswers?: ComplexAnswerPublicJSON[]
    ) => {
      if (!complexAnswers) {
        return;
      }

      complexAnswersAdapter.setAll(
        state,
        complexAnswers.map(mapComplexAnswerPublicJSONToComplexAnswerPublic)
      );
    };

    builder
      .addCase(getInterviewData.fulfilled, (state, action) => {
        setComplexAnswers(state, action.payload.complex_answers);
      })
      .addCase(validateProjectLink.fulfilled, (state, action) => {
        setComplexAnswers(state, action.payload.complex_answers);
      })
      .addCase(initiateInterviewForProjectLink.fulfilled, (state, action) => {
        if ("complex_answers" in action.payload) {
          setComplexAnswers(state, action.payload.complex_answers);
        }
      })
      .addCase(saveComplexAnswer.pending, (state, action) => {
        const { id, response } = action.meta.arg;
        complexAnswersAdapter.updateOne(state, {
          id: id,
          changes: { save_status: SaveStatusEnum.SAVING, response },
        });
      })
      .addCase(saveComplexAnswer.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        complexAnswersAdapter.updateOne(state, {
          id,
          changes: { save_status: SaveStatusEnum.SAVED, error: null },
        });
      })
      .addCase(saveComplexAnswer.rejected, (state, action) => {
        const { id } = action.meta.arg;
        complexAnswersAdapter.updateOne(state, {
          id,
          changes: {
            save_status: SaveStatusEnum.ERROR,
            error: action.error.message,
          },
        });
      });
  },
});

export const {
  complexAnswerAdded,
  complexAnswerUpdated,
  addComplexAnswerForQuestion,
} = complexAnswersSlice.actions;
export default complexAnswersSlice.reducer;

/*
 * Selectors
 */

export const selectAllComplexAnswers = complexAnswersAdapter.getSelectors(
  (state: RootState) => state.complexAnswers
).selectAll;

export const selectComplexAnswerByQuestionId: Selector<
  RootState,
  ComplexAnswerPublic | undefined
> = createSelector(
  [selectAllComplexAnswers, (state, questionId) => questionId],
  (complexAnswers, questionId) =>
    complexAnswers.find((answer) => answer.question_id === questionId)
);

export const selectIdleComplexAnswer: Selector<
  RootState,
  ComplexAnswerPublic | undefined
> = createSelector([selectAllComplexAnswers], (complexAnswers) =>
  // Find the first idle complex answer, which will be shown as the pre-interview survey.
  complexAnswers.find((answer) => answer.save_status === SaveStatusEnum.IDLE)
);

export const selectUnsavedComplexAnswers: Selector<
  RootState,
  ComplexAnswerPublic[]
> = createSelector([selectAllComplexAnswers], (complexAnswers) =>
  complexAnswers.filter((answer) => answer.save_status === SaveStatusEnum.ERROR)
);

export const selectIsSavingComplexAnswers: Selector<RootState, boolean> =
  createSelector([selectAllComplexAnswers], (complexAnswers) =>
    Boolean(
      complexAnswers.find(
        (answer) => answer.save_status === SaveStatusEnum.SAVING
      )
    )
  );

export const selectSavedComplexAnswer: Selector<
  RootState,
  ComplexAnswerPublic | undefined
> = createSelector([selectAllComplexAnswers], (complexAnswers) =>
  // Find the first saved complex answer. Will exist if the pre-interview survey has already been completed.
  complexAnswers.find((answer) => answer.save_status === SaveStatusEnum.SAVED)
);

/*
 * Helpers.
 */

const mapComplexAnswerPublicJSONToComplexAnswerPublic = (
  complexAnswerPublicJSON: ComplexAnswerPublicJSON
): ComplexAnswerPublic => {
  return {
    id: complexAnswerPublicJSON.id,
    question_id: complexAnswerPublicJSON.question_id,
    response: complexAnswerPublicJSON.response,
    save_status: SaveStatusEnum.SAVED,
    error: null,
  };
};
