export type AudioBlobInfo = {
  blob: Blob;
  mimeType: string;
};

// Stores audio blobs in memory for each transcript fragment
export class AudioBlobManager {
  private blobInfos: Record<string, AudioBlobInfo>;

  constructor() {
    this.blobInfos = {};
  }

  addBlob(transcriptFragmentId: string, blobInfo: AudioBlobInfo) {
    this.blobInfos[transcriptFragmentId] = blobInfo;
  }

  getBlob(transcriptFragmentId: string) {
    return this.blobInfos[transcriptFragmentId];
  }
}

export const audioBlobManager = new AudioBlobManager();
