import {
  CheckCircleIcon,
  DocumentArrowUpIcon,
} from "@heroicons/react/24/solid";
import {
  allowedResumeMimeTypes,
  LoadingStatesEnum,
  MAX_UPLOAD_FILE_SIZE_BYTES,
  ResumeCollectionTypesEnum,
} from "app-types";
import { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { FileCard, Loader, LoaderStylesEnum } from "ui";
import { createAxiosInstance } from "../../api/axiosConfig";
import { useAppDispatch } from "../../hooks/hook";
import {
  attachmentAdded,
  resumeRemoved,
  selectInterviewToken,
  selectResumeAttachments,
} from "../transcriptFragments/transcriptFragmentsSlice";

interface ResumeUploadProps {
  resumeCollectionType: ResumeCollectionTypesEnum | null;
}

export const ResumeUpload: FC<ResumeUploadProps> = ({
  resumeCollectionType,
}) => {
  const dispatch = useAppDispatch();
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [uploadStatus, setUploadStatus] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.LOADED
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const interviewToken = useSelector(selectInterviewToken);
  const existingResumes = useSelector(selectResumeAttachments);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: async (files) => {
      const file = files[0];
      if (file.size > MAX_UPLOAD_FILE_SIZE_BYTES) {
        setErrorMessage("File size exceeds 10MB limit.");
        return;
      }
      if (!allowedResumeMimeTypes.includes(file.type)) {
        setErrorMessage(
          "Invalid file type. Please upload a PDF, DOC, DOCX, TXT, or RTF file."
        );
        return;
      }
      setUploadedFile(file);
      setUploadStatus(LoadingStatesEnum.LOADING);
      setErrorMessage(null);

      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_type", "resume");

        const axiosInstance = createAxiosInstance(interviewToken);
        const response = await axiosInstance.post(
          "interview/attachments",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch(attachmentAdded(response.data));

        setUploadStatus(LoadingStatesEnum.LOADED);
        setUploadedFile(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadStatus(LoadingStatesEnum.ERROR);
        setErrorMessage("Failed to upload file. Please try again.");
      }
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections[0]?.file.size > MAX_UPLOAD_FILE_SIZE_BYTES) {
        setErrorMessage("File size exceeds 10MB limit.");
      } else {
        setErrorMessage(
          "Invalid file type. Please upload a PDF, DOC, DOCX, TXT, or RTF file."
        );
      }
    },
    maxFiles: 1,
    maxSize: MAX_UPLOAD_FILE_SIZE_BYTES,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
      "application/rtf": [".rtf"],
      "text/rtf": [".rtf"],
    },
  });

  if (uploadStatus === LoadingStatesEnum.LOADING) {
    return (
      <div className="mt-4 flex items-center">
        <Loader style={LoaderStylesEnum.SPINNER} />
        <p className="text-sm font-medium">Uploading resume...</p>
      </div>
    );
  }

  if (
    (uploadStatus === LoadingStatesEnum.LOADED && uploadedFile) ||
    existingResumes.length > 0
  ) {
    return (
      <div className="mt-4">
        <div className="flex items-center mb-2">
          <CheckCircleIcon className="h-5 w-5 text-green-700 mr-1" />
          <p className="text-sm font-medium">
            Resume successfully uploaded,{" "}
            {resumeCollectionType === ResumeCollectionTypesEnum.BEFORE_INTERVIEW
              ? "you're ready for your interview!"
              : "you're all set!"}
          </p>
        </div>
        {uploadedFile && (
          <div className="mt-2">
            <FileCard
              name={uploadedFile.name}
              onDelete={() => {
                setUploadedFile(undefined);
                setUploadStatus(LoadingStatesEnum.LOADED);
                dispatch(resumeRemoved());
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <p className="mb-2 text-sm font-medium">
        {resumeCollectionType === ResumeCollectionTypesEnum.BEFORE_INTERVIEW
          ? "Please upload your resume so we can personalize your interview:"
          : "To complete your candidate profile, please upload your resume:"}
      </p>
      <div
        {...getRootProps()}
        className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-3 py-5 cursor-pointer"
      >
        <div className="text-center">
          <DocumentArrowUpIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                open();
              }}
              className="relative rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              Click to upload a file
            </button>
            <input {...getInputProps()} />
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">
            PDF, DOC, DOCX, TXT, or RTF up to 10MB
          </p>
          {errorMessage && (
            <p className="text-red-500 text-xs mt-2">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};
