import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

export const DemoInterviewBanner: FC = () => {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-start p-2 z-50"
    >
      <div className="flex w-full flex-col items-center space-y-4">
        <div className="pointer-events-auto w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {
                  <InformationCircleIcon
                    className="h-6 w-6 text-blue-400"
                    aria-hidden="true"
                  />
                }
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">
                  {"This is a demo interview. Answers won't be saved."}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {
                    "Try answering questions to see AI-generated clarifying questions."
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
