import { Transition } from "@headlessui/react";
import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { PosthogEventTypesEnum } from "app-types";
import posthog from "posthog-js";
import { FC, Fragment, useState } from "react";
import { Button, ButtonVariantsEnum } from "ui";
import { AudioVisualizer } from "./audioVisualizer";

export interface InterviewRecorderCTAProps {
  isRecordingAnswer: boolean;
  onClickStartAnswer: () => void;
  onClickSubmitAnswer: (isEmptyResponse: boolean) => void;
  mediaRecorder: MediaRecorder | null;
}

export const InterviewRecorderCTA: FC<InterviewRecorderCTAProps> = ({
  isRecordingAnswer,
  onClickStartAnswer,
  onClickSubmitAnswer,
  mediaRecorder,
}) => {
  const [shouldShowEmptyAnswerError, setShouldShowEmptyAnswerError] =
    useState(false);

  if (!isRecordingAnswer)
    return (
      <Transition
        key={"begin-transcribing"}
        show={true}
        appear={true}
        as={Fragment}
        enter="transform ease-in-out duration-1000 transition"
        enterFrom="translate-y-4 opacity-0"
        enterTo="translate-y-0 opacity-100"
      >
        <div className="flex flex-col space-y-3 justify-center items-center">
          <Button
            onClick={onClickStartAnswer}
            variant={ButtonVariantsEnum.Primary}
          >
            <>
              <MicrophoneIcon className="h-[20px] w-[20px] mr-1" />
              Begin answering
            </>
          </Button>
          {shouldShowEmptyAnswerError ? (
            <div className="text-sm text-orange-500">
              Sorry, your audio response was empty. Please check your mic and
              try answering the question again.
            </div>
          ) : null}
        </div>
      </Transition>
    );

  if (mediaRecorder)
    return (
      <AudioVisualizer
        stream={mediaRecorder.stream}
        onClickSubmitAnswer={(isEmptyResponse) => {
          if (isEmptyResponse) {
            setShouldShowEmptyAnswerError(true);
            posthog.capture(
              PosthogEventTypesEnum.INTERVIEW_EMPTY_AUDIO_RESPONSE
            );
          }

          onClickSubmitAnswer(isEmptyResponse);
        }}
      />
    );

  return null;
};
