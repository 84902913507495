import { Dialog } from "@headlessui/react";
import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { HandlerOf } from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Modal } from "ui";
import { MicrophoneSelect } from "./microphoneSelect";

interface InterviewMicrophoneModalProps {
  microphones: MediaDeviceInfo[];
  setMicrophones: HandlerOf<MediaDeviceInfo[]>;
  setMicrophoneError: (err: Error | null) => void;
  currentMicrophoneDeviceId: string | undefined;
  onUserChangeMicrophone: (newMicId: string) => void;
  fetchMicrophones: () => void;
}

export const InterviewMicrophoneModal: FC<InterviewMicrophoneModalProps> = ({
  setMicrophoneError,
  currentMicrophoneDeviceId,
  onUserChangeMicrophone,
  microphones,
  setMicrophones,
  fetchMicrophones,
}) => {
  const [microphoneId, setMicrophoneId] = useState<string | undefined>(
    currentMicrophoneDeviceId
  );

  return (
    <Modal isOpen={true}>
      <div className="mb-4">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
          <MicrophoneIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <Dialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-700"
        >
          Change microphone
        </Dialog.Title>
        <div className="mt-2 text-center">
          <p className="text-sm text-gray-500">
            Your session is paused. You can choose a new microphone, and then
            pick up from the last question.
          </p>
        </div>
        <div className="mt-4 min-w-[200px]">
          <MicrophoneSelect
            microphones={microphones}
            setMicrophones={setMicrophones}
            onSelectMicrophone={setMicrophoneId}
            currentMicrophoneDeviceId={microphoneId}
            setMicrophoneError={setMicrophoneError}
            fetchMicrophones={fetchMicrophones}
          />
        </div>

        <div className="mt-5 sm:mt-6 flex justify-center">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={"Update microphone"}
            onClick={() => {
              if (microphoneId) onUserChangeMicrophone(microphoneId);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
