/*
 * Supabase types.
 */

import {
  ActivityTypesEnum,
  ComplexQuestionOption,
  ComplexQuestionTypesEnum,
  ProjectQuestion,
  ResumeCollectionTypesEnum,
  SurveyScalesEnum,
} from "./src/client/client.ts";
import type { Tables, TablesInsert, TablesUpdate } from "./src/db/supabase.ts";
import { CustomFieldTypesEnum } from "./src/entities/customField.ts";
import {
  InterviewAgentTypesEnum,
  ProjectEmailDigestFrequencyEnum,
} from "./src/entities/project.ts";
import {
  SalesforceIntegrationSettings,
  SalesforceIntegrationUpsert,
  SlackIntegrationSettings,
  SlackIntegrationUpsert,
} from "./src/integrations/integrations.ts";

export interface ProjectSettings {
  send_email_digest_to_all_teammates: boolean;
  email_digest_recipients: string[];
  email_digest_frequency: ProjectEmailDigestFrequencyEnum;
  max_interview_count: number | null;
  is_incentive_charity_only: boolean;
  is_slack_enabled: boolean;
  slack_channel_id: string | null;
  prompt_context: string | null;
  max_clarifying_questions_count: number | null;
  redirect_url: string | null;
  welcome_message: string | null;
  outro_message: string | null;
  interview_agent_type: InterviewAgentTypesEnum;
  resume_collection_type: ResumeCollectionTypesEnum | null;
}

export enum QuestionsImportSourcesEnum {
  LIST_OF_QUESTIONS = "list_of_questions",
  RESEARCH_DISCUSSION_GUIDE = "research_discussion_guide",
  JOB_DESCRIPTION = "job_description",
}

export type ComplexQuestionDb = Tables<"question">;
export type ComplexQuestion = MatrixQuestion | MultipleChoiceQuestion;

type ComplexQuestionBase = {
  id: string;
  company_id: string;
  project_id: string;
  question: string;
};

export type MatrixQuestion = ComplexQuestionBase & {
  type: ComplexQuestionTypesEnum.RATING;
  options: ComplexQuestionOption[];
  scale: SurveyScalesEnum;
};

export type MultipleChoiceQuestion = ComplexQuestionBase & {
  type: ComplexQuestionTypesEnum.MULTIPLE_CHOICE;
  options: ComplexQuestionOption[];
};

export type RawComplexQuestionInsert = TablesInsert<"question">;
export type ComplexQuestionInsert =
  | Omit<MatrixQuestion, "id" | "project_id">
  | Omit<MultipleChoiceQuestion, "id" | "project_id">;

export type ProjectDbQuestion = { question: string } | { question_id: string };
// When fetching projects from the DB, we also join the `question` table into `complex_questions`
// That way, ProjectDb has all the necessary data to be deserialized into Project
export type ProjectDb = Omit<Tables<"project">, "questions"> & {
  questions: ProjectDbQuestion[];
  complex_questions: ComplexQuestionDb[];
};

export type ProjectInsert = Omit<TablesInsert<"project">, "questions"> & {
  questions: ProjectQuestion[];
};

export type ProjectUpdate = Omit<TablesUpdate<"project">, "questions"> & {
  questions?: ProjectQuestion[];
};

export enum ProjectModesEnum {
  SURVEY = "survey",
  VOICE_AGENT = "voice_agent",
}

export type Project = Omit<
  ProjectDb,
  "questions" | "settings" | "complex_questions" | "mode"
> & {
  questions: ProjectQuestion[];
  settings: ProjectSettings;
  mode: ProjectModesEnum;
};

export type ProjectWithInterviewCount = Project & {
  interview_count: number;
  pending_interview_count: number;
  has_reached_interview_limit: boolean;
};

export type TranscriptFragmentDB = Tables<"transcript_fragment">;
export type TranscriptFragmentInsert = TablesInsert<"transcript_fragment">;

export interface TranscriptFragmentDbWithDynamicFragmentsList
  extends TranscriptFragmentDB {
  dynamic_transcript_fragment_ids?: string[];
}

export type AccountDb = Tables<"account">;
export type AccountInsert = TablesInsert<"account">;
export type AccountUpdate = TablesUpdate<"account">;
export type Account = {
  id: string;
  ext_id: string;
  name: string;
  custom_fields: CustomField[];
  created_at: string;
  updated_at: string | null;
};

export type ActivityDb = Tables<"activity">;
export type ActivityInsert = TablesInsert<"activity">;
export type Activity = Omit<ActivityDb, "type"> & {
  type: ActivityTypesEnum;
};

export enum CompanyInvitationStatusesEnum {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}
export type CompanyInvitationDb = Tables<"company_invitation">;
export type CompanyInvitationInsert = TablesInsert<"company_invitation">;
export type CompanyInvitationUpdate = TablesUpdate<"company_invitation">;
export type CompanyInvitation = {
  id: string;
  company_id: string;
  recipient_email: string;
  invited_by_teammate_id: string;
  status: CompanyInvitationStatusesEnum;
  company_name: string;
  sender_full_name: string;
  sender_email: string;
  reminder_email_count: number;
  expires_at: string;
  created_at: string;
};

export enum CompanyInvitationErrorsEnum {
  NO_EMAIL_MATCH = "no_email_match",
  EXPIRED = "expired",
  NOT_FOUND = "not_found",
  ALREADY_JOINED = "already_joined",
}

export enum InterviewAccessErrorsEnum {
  NOT_LIVE = "not_live",
  NOT_FOUND = "not_found",
  LIMIT_REACHED = "limit_reached",
  RATE_LIMIT_EXCEEDED = "rate_limit_exceeded",
  UNKNOWN = "unknown",
}

export type Keyword = {
  phrase: string;
  transcript_fragment_ids: string[] | null;
  analyses: KeywordAnalysis[];
};

export type KeywordAnalysis = {
  type: KeywordAnalysisTypesEnum;
  values: KeywordAnalysisValue[];
};

type KeywordAnalysisValue = {
  score: number;
  label: string;
};

export enum KeywordAnalysisTypesEnum {
  COMPETITIVE_STRENGTH = "competitive_strength",
  COMPETITIVE_WEAKNESS = "competitive_weakness",
}

export enum KeywordTrackerTypesEnum {
  COMPANY_COMPETITORS = "lexical", // preserve "lexical" to avoid db backfill
  COMPETITIVE = "competitive", // project-level competitive insights (inherits phrases from COMPANY_COMPETITORS)
  QUESTION_SUMMARY = "question_summary",
}

export type KeywordTrackerDb = Tables<"keyword_tracker">;
export type KeywordTrackerInsert = TablesInsert<"keyword_tracker">;
export type KeywordTrackerUpdate = TablesUpdate<"keyword_tracker">;
export type KeywordTracker = {
  id: string;
  name: string;
  keywords: Keyword[];
  type: KeywordTrackerTypesEnum;
  project_id: string | null;
  question: string | null;
  created_at: string;
  updated_at: string | null;
};

export type KeywordMatches = {
  transcript_fragment_ids: string[];
  interview_ids: string[];
};

export type KeywordTrackerMatches = Record<string, KeywordMatches>;

export enum IntegrationProvidersEnum {
  SALESFORCE = "salesforce",
  SLACK = "slack",
}

export type IntegrationDb = Tables<"integration">;
export type IntegrationInsert = TablesInsert<"integration">;
export type IntegrationUpdate = TablesUpdate<"integration">;

export type IntegrationSettings =
  | SalesforceIntegrationSettings
  | SlackIntegrationSettings;

export type Integration = {
  id: string;
  provider: IntegrationProvidersEnum;
  settings: IntegrationSettings;
  created_at: string;
  updated_at: string | null;
};

export interface IntegrationWithCredentials extends Integration {
  access_token: string | null;
  refresh_token: string | null;
}

export type IntegrationUpsert =
  | SalesforceIntegrationUpsert
  | SlackIntegrationUpsert;

export type CustomFieldDefinitionDb = Tables<"custom_field_definition">;
export type CustomFieldDefinitionInsert =
  TablesInsert<"custom_field_definition">;
export type CustomFieldDefinitionUpdate =
  TablesUpdate<"custom_field_definition">;

export type CustomFieldDefinitionInsertClient = Omit<
  CustomFieldDefinitionInsert,
  "company_id"
>;

export type CustomFieldValueDb = Tables<"custom_field_value">;
export type CustomFieldValueInsert = TablesInsert<"custom_field_value">;
export type CustomFieldValueUpdate = TablesUpdate<"custom_field_value">;

export type CustomFieldValueData = {
  value: string | number | Date | boolean | null;
  display_value: string | undefined;
};

export type CustomFieldValue = {
  id: string;
  custom_field_definition_id: string;
  resource_id: string;
  resource_type: CustomFieldResourceTypesEnum;
  data: CustomFieldValueData;
  created_at: string;
  updated_at: string | null;
};

export enum CustomFieldResourceTypesEnum {
  CONTACT = "contact",
  ACCOUNT = "account",
  INTERVIEW = "interview",
}

// The metadata required to allow user to pick external field to sync
export type CustomFieldMetadata = {
  provider: IntegrationProvidersEnum | "alpharun";
  resource_type: CustomFieldResourceTypesEnum;
  display_name: string;
  field_name: string;
  field_type: CustomFieldTypesEnum;
};

export const customFieldDefinitionSettingsDefaults: CustomFieldDefinitionSettings =
  {
    description: null,
  };

export type CustomFieldDefinitionSettings = {
  description: string | null; // Not used yet - for future control of the meaning of an AI criteria
};

// A custom field definition that has been saved
export type CustomFieldDefinition = CustomFieldMetadata & {
  id: string;
  created_at: string;
  updated_at: string | null;
};

// Partial combination of a field definition + value that's used for display on the client
export type CustomField = {
  custom_field_definition_id: string;
  data: CustomFieldValueData;
  provider: IntegrationProvidersEnum | "alpharun";
  display_name: string;
  field_name: string;
  field_type: CustomFieldTypesEnum;
};

export type BillingProductInfo = {
  billing_period_starts_at: string;
  billing_period_ends_at: string;
  usage_count: number | null;
  product_name: string;
};

export type ApiKeyDb = Tables<"api_key">;
export type ApiKeyInsert = TablesInsert<"api_key">;
export type ApiKeyUpdate = TablesUpdate<"api_key">;
export type ApiKey = {
  id: string;
  name: string;
  key_suffix: string;
  created_at: string;
  updated_at: string | null;
};

export type ApiKeyWithFullKey = ApiKey & {
  key: string;
};

export enum CurrencyCodesEnum {
  USD = "usd",
}
export enum BalanceTransactionTypesEnum {
  STRIPE_CREDIT = "stripe_credit",
  TREMENDOUS_DEBIT = "tremendous_debit",
  NO_INCENTIVE_INTERVIEW = "no_incentive_interview",
  ALPHARUN_CREDIT = "alpharun_credit", // for occasionally waiving gift card costs for interview issues
}
export enum BalanceTransactionStatusesEnum {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  FAILED = "failed",
}
export type BalanceTransactionDb = Tables<"balance_transaction">;
export type BalanceTransactionInsert = {
  id: string;
  company_id: string;
  external_id: string;
  type: BalanceTransactionTypesEnum;
  status: BalanceTransactionStatusesEnum;
  unit_amount: number; // the amount in the smallest currency unit (USD cents until we support other currencies)
  currency: string;
  interview_id: string | undefined;
};

export type BalanceTransaction = {
  id: string;
  description: string;
  unit_amount: number;
  type: BalanceTransactionTypesEnum;
  status: BalanceTransactionStatusesEnum;
  interview_id: string | null;
  created_at: string;
  updated_at: string | null;
};

// Payment methods like Stripe Link dont have card details
export type PaymentMethodInfo =
  | {
      brand: string;
      last4: string;
      exp_month: number;
      exp_year: number;
      payment_method_type: string;
      is_card: true;
    }
  | { payment_method_type: string; is_card: false };

/*
 * Client types.
 */

export * from "./src/client/chartTypes.ts";
export * from "./src/client/client.ts";

/*
 * Helpers.
 */

export const projectSettingsDefaults: ProjectSettings = {
  send_email_digest_to_all_teammates: true,
  email_digest_recipients: [],
  email_digest_frequency: ProjectEmailDigestFrequencyEnum.DAILY,
  max_interview_count: null,
  is_slack_enabled: true,
  slack_channel_id: null,
  prompt_context: null,
  max_clarifying_questions_count: 2,
  redirect_url: null,
  welcome_message: null,
  outro_message: null,
  interview_agent_type: InterviewAgentTypesEnum.GENERIC_INTERVIEWER,
  is_incentive_charity_only: false,
  resume_collection_type: null,
};

export const mapProjectDbToProject = (projectDb: ProjectDb): Project => {
  const questions = (projectDb.questions || []).map((q) => {
    if ("question_id" in q) {
      const matchingComplexQuestion = projectDb.complex_questions.find(
        (cq: any) => cq.id === q.question_id
      );

      if (!matchingComplexQuestion) {
        throw new Error(`Complex question with id ${q.question_id} not found`);
      }

      return mapComplexQuestionDbToComplexQuestion(matchingComplexQuestion);
    }

    return q;
  }) as ProjectQuestion[];

  return {
    ...projectDb,
    questions,
    settings: mapProjectSettingsDbToProjectSettings(projectDb.settings),
    mode: (projectDb.mode || ProjectModesEnum.SURVEY) as ProjectModesEnum,
  };
};

export const mapProjectSettingsDbToProjectSettings = (
  projectSettingsDb: any
) => {
  const settings: ProjectSettings = {
    ...projectSettingsDefaults,
    ...(typeof projectSettingsDb === "object" ? projectSettingsDb : {}),
  };

  return settings;
};

export const mapComplexQuestionDbToComplexQuestion = (
  questionDb: ComplexQuestionDb
): ComplexQuestion => {
  return {
    id: questionDb.id,
    company_id: questionDb.company_id,
    project_id: questionDb.project_id,
    question: questionDb.question,
    type: questionDb.type as ComplexQuestionTypesEnum,
    options: questionDb.options as unknown as ComplexQuestionOption[],
    scale: questionDb.scale as SurveyScalesEnum,
  };
};

/*
 * Shared constants.
 */

export const DEMO_INTERVIEW_ID = "demo-interview";
export const QUESTION_SUMMARY_MAX_INTERVIEW_COUNT = 25; // Summarize the 25 most recent interview responses for a given question
export const QUESTION_SUMMARY_MIN_INTERVIEW_COUNT = 5; // Don't summarize until we have at least 5 responses

export const MAX_GIFT_CARD_BALANCE_INCREASE_USD = 2000;
export const MIN_GIFT_CARD_BALANCE_INCREASE_USD = 100;

export const MAX_UPLOAD_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10MB

export const COMPETITORS_KEYWORD_TRACKER_NAME = "Competitors";

export { ProjectTypeToDefaultQuestions } from "./src/constants/projectTypeToDefaultQuestions.ts";
export * from "./src/constants/whitelabelConstants.ts";

/*
 * Shared utilities.
 */

export * from "./src/helpers/publicStorage.ts";
export * from "./src/helpers/utilities.ts";

export * from "./src/integrations/integrations.ts";

export * from "./src/helpers/billingFeatureGating.ts";
export * from "./src/helpers/smsHelpers.ts";

/**
 * Business entities
 */
export * from "./src/entities/assessment.ts";
export * from "./src/entities/attachment.ts";
export * from "./src/entities/company.ts";
export * from "./src/entities/complexAnswer.ts";
export * from "./src/entities/contact.ts";
export * from "./src/entities/customField.ts";
export * from "./src/entities/interview.ts";
export * from "./src/entities/phoneNumber.ts";
export * from "./src/entities/phoneNumberExtension.ts";
export * from "./src/entities/project.ts";
export * from "./src/entities/teammate.ts";
export * from "./src/entities/transcriptFragment.ts";
