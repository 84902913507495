import { Transition } from "@headlessui/react";
import {
  ComplexInterviewQuestion,
  InterviewPublic,
  TranscriptFragmentSaveStatusEnum,
  isComplexInterviewQuestion,
} from "app-types";
import { FC, Fragment } from "react";
import { useAppSelector } from "../../hooks/hook";
import { selectIdleComplexAnswer } from "../complexAnswers/complexAnswersSlice";
import {
  TranscriptFragmentPublic,
  selectCurrentQuestionNumber,
  selectLatestTranscriptFragmentType,
} from "../transcriptFragments/transcriptFragmentsSlice";

interface InterviewQuestionSectionProps {
  fragmentOrSurveyQuestion: TranscriptFragmentPublic | ComplexInterviewQuestion;
  interview: InterviewPublic;
}

const fragmentTypeToCopy = {
  preset: "",
  dynamic: ": Clarifying question",
  final_dynamic: ": Final clarifying question",
};

export const InterviewQuestionSection: FC<InterviewQuestionSectionProps> = ({
  fragmentOrSurveyQuestion,
  interview,
}) => {
  const latestTranscriptFragmentType = useAppSelector(
    selectLatestTranscriptFragmentType
  );
  const currentQuestionNumber = useAppSelector(selectCurrentQuestionNumber);
  const surveyQuestionAnswer = useAppSelector(selectIdleComplexAnswer);

  const isArgComplexSurveyQuestion = getIsArgComplexInterviewQuestion(
    fragmentOrSurveyQuestion
  );

  const saveStatus = isArgComplexSurveyQuestion
    ? surveyQuestionAnswer?.save_status
    : fragmentOrSurveyQuestion.save_status;

  const renderQuestion = () => {
    // If we're rendering a survey (not a standard interview question), don't stream the text.
    if (isArgComplexSurveyQuestion) {
      return fragmentOrSurveyQuestion.question;
    }

    return (
      <Transition
        key={"interview-question"}
        show={true}
        appear={true}
        as={Fragment}
        enter="transform ease-in-out duration-1000 transition"
        enterFrom="translate-y-4 opacity-0"
        enterTo="translate-y-0 opacity-100"
      >
        <div>{fragmentOrSurveyQuestion.question}</div>
      </Transition>
    );
  };

  return (
    <>
      <h1 className="text-xl font-medium mb-2 text-gray-800">
        {saveStatus === TranscriptFragmentSaveStatusEnum.SAVING ? (
          <div role="status" className="animate-pulse">
            <div className="h-3 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-2.5"></div>
            <div className="h-3 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[80%]"></div>{" "}
          </div>
        ) : (
          <>
            <div className="text-gray-600 text-sm">
              {`Question ${currentQuestionNumber} of ${
                interview.questions.filter((q) => {
                  if (isComplexInterviewQuestion(q)) return true;

                  return q.type === "preset";
                }).length
              }`}
              {latestTranscriptFragmentType
                ? fragmentTypeToCopy[latestTranscriptFragmentType]
                : undefined}
            </div>
            {renderQuestion()}
          </>
        )}
      </h1>
    </>
  );
};

function getIsArgComplexInterviewQuestion(
  obj: TranscriptFragmentPublic | ComplexInterviewQuestion
): obj is ComplexInterviewQuestion {
  // Checking for the presence of a unique property of ComplexInterviewQuestion
  return "options" in obj && "scale" in obj && "type" in obj;
}
