import { Transition } from "@headlessui/react";
import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { FC, Fragment } from "react";

export const AllowMicrophoneBanner: FC<{
  isRequestingMicrophoneAccess: boolean;
}> = ({ isRequestingMicrophoneAccess }) => {
  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6"
      >
        <div className="flex w-full flex-col items-center space-y-4">
          <Transition
            show={isRequestingMicrophoneAccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <MicrophoneIcon
                      className="h-6 w-6 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Allow microphone access to continue
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Click "Allow" when prompted by your browser
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
