import { CheckIcon } from "@heroicons/react/24/outline";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonVariantsEnum } from "ui";

interface AudioVisualizerProps {
  stream: MediaStream;
  onClickSubmitAnswer: (isEmptyResponse: boolean) => void;
}

const silenceVolumeThreshold = 10; // Ignore white noise (this value is a bit arbitray and based on some manual testing)
const stoppedTalkingDurationThreshold = 1000; // 1 seconds
const speakingDurationThreshold = 600;
const bestAnswerDurationMillis = 15 * 1000; // 15 seconds
const volumeCheckIntervalMillis = 100;

export const AudioVisualizer: FC<AudioVisualizerProps> = ({
  stream,
  onClickSubmitAnswer,
}) => {
  const [disableNextQuestionButton, setDisableNextQuestionButton] =
    useState(true);

  const [answerQuality, setAnswerQuality] = useState(0);

  const audioContextRef = useRef<AudioContext | null>(null);
  const analyzerRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);

  const silenceDurationRef = useRef(0);
  const speakingDurationRef = useRef(0);
  const totalSpeakingDurationRef = useRef(0);

  // Initialization
  useEffect(() => {
    // Initialize AudioContext and Analyzer
    audioContextRef.current = new (window.AudioContext ||
      // @ts-ignore
      window.webkitAudioContext)();
    analyzerRef.current = audioContextRef.current.createAnalyser();
    dataArrayRef.current = new Uint8Array(
      analyzerRef.current.frequencyBinCount
    );

    const source = audioContextRef.current.createMediaStreamSource(stream);
    source.connect(analyzerRef.current);

    // Cleanup function to disconnect and close the audio context
    return () => {
      source && source.disconnect();
      audioContextRef?.current?.close();
    };
  }, [stream]);

  // 10 second max timeout to call onUserStopTalking so that we re-enable the next question button
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisableNextQuestionButton(false);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Needs to do 3 things:
  // - Track when the user has spoken for at least 1 second (indicates they've answered question)
  // - Track when the user stops speaking after having spoken (indicates they've finished answering question)
  const checkVolume = useCallback(
    (avgVolume: number) => {
      // Handle silence
      if (avgVolume < silenceVolumeThreshold) {
        silenceDurationRef.current += volumeCheckIntervalMillis;

        // If we've been silent for 2 seconds and have spoken, notify the parent
        if (silenceDurationRef.current >= stoppedTalkingDurationThreshold) {
          if (answerQuality > 0) {
            setDisableNextQuestionButton(false);
          }
          // User is no longer talking, reset speaking duration
          speakingDurationRef.current = 0;
        }
      } else {
        // Handle speaking
        speakingDurationRef.current += volumeCheckIntervalMillis; // Increment speaking duration
        totalSpeakingDurationRef.current += volumeCheckIntervalMillis;
        silenceDurationRef.current = 0;

        // Require some sustained volume before we start tracking answer quality
        if (
          speakingDurationRef.current < speakingDurationThreshold &&
          answerQuality === 0
        )
          return;

        const newQuality = Math.min(
          100,
          Math.round(
            (totalSpeakingDurationRef.current / bestAnswerDurationMillis) * 100
          )
        );

        // Debounce state updates by not updating for every answerQuality change
        if (answerQuality === 0 || newQuality - answerQuality > 10) {
          setAnswerQuality(newQuality);
        }
      }
    },
    [answerQuality]
  );

  useEffect(() => {
    // Separate interval for volume checking
    const volumeCheckInterval = setInterval(() => {
      if (analyzerRef.current && dataArrayRef.current) {
        analyzerRef.current.getByteFrequencyData(dataArrayRef.current);
        const avgVolume =
          dataArrayRef.current
            .slice(0, dataArrayRef.current.length / 2)
            .reduce((acc, val) => acc + val, 0) /
          (dataArrayRef.current.length / 2);
        checkVolume(avgVolume);
      }
    }, volumeCheckIntervalMillis);

    return () => {
      clearInterval(volumeCheckInterval);
    };
  }, [checkVolume]);

  return (
    <>
      <div className="flex flex-col items-center gap-1.5 min-w-full">
        <div className="mt-1 flex flex-col">
          <div className="w-[150px] bg-gray-200 h-2 rounded-full text-center relative">
            <div
              className={`bg-green-400 h-full rounded-full text-center absolute top-0 left-0`}
              style={{
                width: `${answerQuality}%`,
                transition: "width 1s ease-in-out",
              }}
            ></div>
          </div>
        </div>
        <span className="text-sm text-gray-700 my-1">
          {answerQuality > 0
            ? "Continue speaking to improve your answer quality"
            : "Start speaking"}
        </span>
      </div>
      <div className="flex mt-4 justify-center items-center">
        <Button
          onClick={() =>
            onClickSubmitAnswer(
              totalSpeakingDurationRef.current < speakingDurationThreshold
            )
          }
          variant={ButtonVariantsEnum.Secondary}
          isDisabled={disableNextQuestionButton}
        >
          <>
            <CheckIcon className="h-[20px] w-[20px] mr-1" />
            Done answering
          </>
        </Button>
      </div>
    </>
  );
};
