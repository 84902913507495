import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { HandlerOf } from "app-types";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  ModalVariantsEnum,
  SimpleModal,
  SizesEnum,
} from "ui";
import { InterviewMicrophoneModal } from "./interviewMicrophoneModal";

interface InterviewMicrophoneControlsProps {
  stopAnswering: HandlerOf<boolean>;
  microphones: MediaDeviceInfo[];
  setMicrophones: HandlerOf<MediaDeviceInfo[]>;
  currentMicrophoneDeviceId: string | undefined;
  microphoneIdToSwitchTo: string | undefined;
  setMicrophoneIdToSwitchTo: HandlerOf<string>;
  setMicrophoneError: (err: Error | null) => void;
  isRecordingAnswer: boolean;
  isReadOnly?: boolean;
}

/*
 * This component loads the available mics on mount and starts/stops a stream to get the mic that's defaulted to
 * InterviewMicrophoneModal allows the user to switch mics
 */

export const InterviewMicrophoneControls: FC<
  InterviewMicrophoneControlsProps
> = ({
  stopAnswering,
  microphones,
  setMicrophones,
  currentMicrophoneDeviceId,
  microphoneIdToSwitchTo,
  setMicrophoneError,
  setMicrophoneIdToSwitchTo,
  isRecordingAnswer,
  isReadOnly = false,
}) => {
  const [changeMicModalIsOpen, setChangeMicModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(microphones.length === 0);

  const currentMic =
    microphones.find(
      (m) =>
        m.deviceId ===
        (isRecordingAnswer && currentMicrophoneDeviceId
          ? currentMicrophoneDeviceId
          : microphoneIdToSwitchTo) // Show the current mic if we're recording, otherwise show the mic we'll switch to
    ) ||
    microphones.find((m) => m.deviceId === "default") || // Chrome-only "default" option
    (microphones.length > 0 && microphones[0]);

  const fetchMicrophones = async () => {
    setIsLoading(true);
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const mics = devices.filter((device) => device.kind === "audioinput");
      setMicrophones(mics);
    } catch (err) {
      // Set error
      if (err instanceof Error) setMicrophoneError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Load the microphones on initial load
    if (microphones.length === 0) fetchMicrophones();

    // Start and stop a stream to get the mic that's being defaulted to.
    // User should have already granted audio permissions
    if (!currentMic) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          // Get the microphone device ID that was defaulted to (no reliable way to get this from "enumerateDevices")
          const currentAudioTrack = stream.getAudioTracks()[0];
          const currentMicId =
            currentAudioTrack && currentAudioTrack.getSettings().deviceId;
          if (currentMicId) setMicrophoneIdToSwitchTo(currentMicId);

          // Stop the stream
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((err) => {
          setMicrophoneError(err);
        });
    }
  }, []);

  if (isLoading && microphones.length === 0) return null;

  if (!currentMic)
    return (
      <SimpleModal
        isOpen={true}
        variant={ModalVariantsEnum.Standard}
        title="No microphone found"
        subtitle="Please connect a microphone, ensure that you've granted microphone permissions, and refresh the page. Need help? Reach out to us at support@alpharun.com."
        confirmButtonText="Refresh this page"
        onConfirm={() => {
          window.location.reload();
        }}
      />
    );

  if (isReadOnly) {
    return (
      <div>
        <Label size={SizesEnum.SMALL} className="flex items-center">
          Microphone
        </Label>
        <div className="text-sm text-gray-600">
          {currentMic?.label || "Unknown microphone"}
        </div>
      </div>
    );
  }

  return (
    <>
      <Button
        variant={ButtonVariantsEnum.Secondary}
        onClick={() => {
          stopAnswering(true);
          setChangeMicModalIsOpen(true);
        }}
        icon={<MicrophoneIcon className="h-[18px] w-[18px] mr-1" />}
        label={
          currentMic?.label ? `Microphone: ${currentMic.label}` : "Choose a mic"
        }
      />
      {changeMicModalIsOpen && (
        <InterviewMicrophoneModal
          microphones={microphones}
          setMicrophones={setMicrophones}
          setMicrophoneError={setMicrophoneError}
          currentMicrophoneDeviceId={currentMic.deviceId}
          onUserChangeMicrophone={(newMicId) => {
            setChangeMicModalIsOpen(false);
            setMicrophoneIdToSwitchTo(newMicId);
          }}
          fetchMicrophones={fetchMicrophones}
        />
      )}
    </>
  );
};
