import {
  ArrowPathIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  InterviewPublic,
  PosthogEventTypesEnum,
  TranscriptFragmentSaveStatusEnum,
} from "app-types";
import posthog from "posthog-js";
import { FC, useEffect, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";
import { Button, ButtonVariantsEnum } from "ui";
import { audioBlobManager } from "../../app/audioBlobManager";
import {
  cleanUpMediaRecorder,
  getMediaRecorder,
} from "../../helpers/mediaRecorderHelpers";
import { getUnansweredSurveyQuestion } from "../../helpers/utilities";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  ComplexAnswerPublic,
  addComplexAnswerForQuestion,
  saveComplexAnswer,
  selectIdleComplexAnswer,
  selectSavedComplexAnswer,
} from "../complexAnswers/complexAnswersSlice";
import { InterviewOnboarding } from "../interviewOnboarding/interviewOnboarding";
import { InterviewTermsModal } from "../interviewOnboarding/interviewTermsModal";
import { selectProjectLinkState } from "../projectLink/projectLinkSlice";
import { SurveyQuestionSection } from "../survey/surveyQuestionSection";
import {
  addNextTranscriptFragment,
  postTranscriptFragment,
  selectLatestTranscriptFragment,
  transcriptFragmentUpdated,
} from "../transcriptFragments/transcriptFragmentsSlice";
import { InterviewMicrophoneControls } from "./interviewMicrophoneControls";
import { InterviewMicrophoneSetup } from "./interviewMicrophoneSetup";
import { InterviewQuestionSection } from "./interviewQuestionSection";
import { InterviewRecorderActionSection } from "./interviewRecorderActionSection";
import { SaveRetry } from "./saveRetry";

interface InterviewRecorderProps {
  interview: InterviewPublic | null;
}

// For some strange reason, openai whisper only works with safari's mp4 when blobs are chunked with this interval
// https://community.openai.com/t/whisper-problem-with-audio-mp4-blobs-from-safari/322252
const DATA_AVAILABLE_INTERVAL_MS = 1000;

export const InterviewRecorder: FC<InterviewRecorderProps> = ({
  interview,
}) => {
  const dispatch = useAppDispatch();

  const latestTranscriptFragment = useAppSelector(
    selectLatestTranscriptFragment
  );
  const hasLiveFragment =
    latestTranscriptFragment &&
    latestTranscriptFragment.save_status !==
      TranscriptFragmentSaveStatusEnum.SAVED;

  const unansweredSurveyQuestion = interview
    ? getUnansweredSurveyQuestion(interview)
    : undefined;
  const idleSurveyAnswer = useAppSelector(selectIdleComplexAnswer);
  const existingSavedSurveyAnswer = useAppSelector(selectSavedComplexAnswer);
  const { project } = useAppSelector(selectProjectLinkState);

  // Enable additional posthog tracking if possible
  useEffect(() => {
    if (project && !project.has_sensitive_data) {
      posthog.set_config({
        autocapture: true,
        disable_session_recording: false,
      });
    }
  }, []);

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const [microphoneError, setMicrophoneError] = useState<Error | null>(null);
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [microphoneIdToSwitchTo, setMicrophoneIdToSwitchTo] = useState<
    string | undefined
  >(undefined);
  const [micPermission, setMicPermission] = useState<
    PermissionState | undefined
  >();

  // State to manage the recording
  const [isRecordingAnswer, setIsRecordingAnswer] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const currentAudioTrack = mediaRecorder?.stream?.getAudioTracks()[0];
  const currentMicrophoneDeviceId =
    currentAudioTrack && currentAudioTrack.getSettings().deviceId;

  // Stores audio chunks as they become available each second
  const recordedChunks = useRef<Blob[]>([]);

  // Allows us to track when "mediaRecorder.onStop" is called b/c user submitted answer (vs some other reason)
  const waitingAfterAnswerSubmitRef = useRef(false);

  // This function is called every 1 second as data becomes available
  const onDataAvailableHandler = (e: BlobEvent) => {
    // Push to recorded chunks
    if (e.data.size > 0) recordedChunks.current.push(e.data);
  };

  // We need to use a ref so that mediaRecorder.ondataavailable can access the latest value of this function
  const onDataAvailableHandlerRef = useRef(onDataAvailableHandler);
  useEffect(() => {
    onDataAvailableHandlerRef.current = onDataAvailableHandler;
  }, [onDataAvailableHandler]);

  const onStopHandler = (ev: Event) => {
    if (!waitingAfterAnswerSubmitRef.current) {
      // Audio recorder was stopped somehow, but not because we submitted an answer (hot reload, bug, etc)
      // Pause the session because we got interrupted somehow
      stopAnswering(true);
      return;
    }

    // Reset the flag now that we're processing this submission
    waitingAfterAnswerSubmitRef.current = false;

    if (!mediaRecorder) throw new Error("No media recorder");

    if (!latestTranscriptFragment)
      throw new Error("Missing transcript fragment for recording");

    if (
      latestTranscriptFragment.save_status ===
      TranscriptFragmentSaveStatusEnum.SAVED
    )
      throw new Error("Attempting to overwrite saved transcript fragment");

    // We've finished answering a question: update our transcriptFragment state, then save + retrieve next question
    dispatch(
      transcriptFragmentUpdated({
        id: latestTranscriptFragment.id,
        changes: {
          end_time: new Date().getTime(),
        },
      })
    );
    audioBlobManager.addBlob(latestTranscriptFragment.id, {
      // deep copy to avoid race condition
      blob: new Blob([...recordedChunks.current], {
        type: mediaRecorder.mimeType,
      }),
      mimeType: mediaRecorder.mimeType,
    });
    dispatch(
      postTranscriptFragment({
        id: latestTranscriptFragment.id,
        nextTranscriptFragmentStartTime: new Date().getTime(),
      })
    );

    // Reset recorded chunks
    recordedChunks.current = [];
  };
  const onStopHandlerRef = useRef(onStopHandler);
  useEffect(() => {
    onStopHandlerRef.current = onStopHandler;
  }, [onStopHandler]);

  // When this component unmounts (ex. we've completed interview), cleanup the mediaRecorder
  useEffect(() => {
    return () => {
      cleanUpMediaRecorder(mediaRecorder);
    };
  }, [mediaRecorder]);

  // Called after user completes microphone setup: setup mediaRecorder + add first transcriptFragment
  const startNewMediaStream = async (audioDeviceId: string | undefined) => {
    recordedChunks.current = [];
    try {
      const recorder = await getMediaRecorder(audioDeviceId);

      setMediaRecorder(recorder);

      // This function is called every time the recorder stops and audio is available
      recorder.ondataavailable = (e) => onDataAvailableHandlerRef.current(e);
      recorder.onstop = (ev) => onStopHandlerRef.current(ev);

      recorder.start(DATA_AVAILABLE_INTERVAL_MS);

      setIsRecordingAnswer(true);
    } catch (err: any) {
      console.log("Microphone error: ", err);
      setMicrophoneError(err);
    }
  };

  const onClickSubmitAnswer = (isEmptyResponse: boolean) => {
    if (!mediaRecorder) throw new Error("No media recorder after answering");

    // If the user provides an empty response, stop answering and discard audio chunks
    if (isEmptyResponse) {
      return stopAnswering(true);
    }

    // Set flag before stopping so we know that audio should be saved
    waitingAfterAnswerSubmitRef.current = true;
    stopAnswering(false);
  };

  const stopAnswering = (shouldDiscardAudioChunks: boolean) => {
    if (!mediaRecorder || !isRecordingAnswer) return;

    // Preserve the currently selected microphone
    setMicrophoneIdToSwitchTo(currentMicrophoneDeviceId);

    // If we're restarting an answer or changing mics we discard the audio chunks
    // Otherwise we're submitting an answer and want to keep them
    if (shouldDiscardAudioChunks) recordedChunks.current = [];

    // On mobile safari it's not sufficient to just stop the mediaRecorder - we also need to stop all tracks
    cleanUpMediaRecorder(mediaRecorder);

    // Update UI only now that we've stopped the media recorder: ensures we don't show a "recording" UI before initiating async actions
    setIsRecordingAnswer(false);
  };

  const onClickStartAnswer = () => {
    // Update the transcriptFragment to set the start time to now
    if (!latestTranscriptFragment)
      throw new Error("Missing transcript fragment for recording");

    dispatch(
      transcriptFragmentUpdated({
        id: latestTranscriptFragment.id,
        changes: {
          start_time: new Date().getTime(),
        },
      })
    );

    startNewMediaStream(microphoneIdToSwitchTo);
  };

  const moveToNextQuestion = (
    interview: InterviewPublic,
    lastAnswer?: ComplexAnswerPublic
  ) => {
    const surveyQuestion = getUnansweredSurveyQuestion(interview, lastAnswer);
    if (surveyQuestion) {
      dispatch(
        addComplexAnswerForQuestion({
          questionId: surveyQuestion.id,
        })
      );
    } else {
      dispatch(
        addNextTranscriptFragment({
          nextTranscriptFragmentStartTime: new Date().getTime(),
          dynamicQuestion: undefined,
        })
      );
    }
  };

  if ((!hasLiveFragment && !idleSurveyAnswer) || !interview) {
    return (
      <>
        <InterviewOnboarding
          onClickStartInterview={(startedInterview: InterviewPublic) => {
            posthog.capture(PosthogEventTypesEnum.INTERVIEW_START, {
              interview_id: startedInterview?.id,
              company_name: project?.company_name,
            });

            // Special case: show terms modal for Alpharun Research
            if (project?.company_name === "Alpharun Research") {
              setIsTermsModalOpen(true);
            } else {
              moveToNextQuestion(startedInterview);
            }
          }}
          // If we have a previously saved fragment, we must be resuming this session after closing
          isResuming={
            Boolean(existingSavedSurveyAnswer) ||
            Boolean(latestTranscriptFragment)
          }
          interview={interview}
        />
        <InterviewTermsModal
          isOpen={isTermsModalOpen}
          onClickDone={() => {
            setIsTermsModalOpen(false);

            // Should always have an interview by this point
            if (!interview)
              throw new Error("Missing interview after opening terms modal");

            moveToNextQuestion(interview);
          }}
        />
      </>
    );
  }

  if (idleSurveyAnswer) {
    if (!unansweredSurveyQuestion)
      throw new Error(
        "Unanswered survey question should exist if idle survey answer is set"
      );

    return (
      <SurveyQuestionSection
        question={unansweredSurveyQuestion}
        answer={idleSurveyAnswer}
        interview={interview}
        onSaveComplexAnswer={(answer: ComplexAnswerPublic) => {
          dispatch(saveComplexAnswer(answer));
          moveToNextQuestion(interview, answer);
        }}
      />
    );
  }

  /*
   * States we need to support:
   * - User hasn't granted audio permissions yet or there's a mic error: show InterviewMicrophoneSetup
   * - User is answering a question
   * - We're loading the next question
   */

  if (!latestTranscriptFragment)
    throw new Error("Missing transcript fragment for recording");

  if (micPermission !== "granted" || microphoneError) {
    return (
      <div className="flex flex-col items-center min-h-full sm:min-h-0">
        <div className="box-border p-6 w-full">
          <InterviewQuestionSection
            fragmentOrSurveyQuestion={latestTranscriptFragment}
            interview={interview}
          />
          <div className="flex flex-col justify-center items-center min-h-[140px]">
            <InterviewMicrophoneSetup
              microphones={microphones}
              setMicrophones={setMicrophones}
              microphoneError={microphoneError}
              setMicrophoneError={setMicrophoneError}
              micPermission={micPermission}
              setMicPermission={setMicPermission}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SaveRetry
        isBackgroundMode={true}
        latestTranscriptFragmentId={latestTranscriptFragment.id}
      />
      <div className="flex flex-col items-center min-h-full sm:min-h-0">
        <div className="box-border p-6 w-full">
          <InterviewQuestionSection
            fragmentOrSurveyQuestion={latestTranscriptFragment}
            interview={interview}
          />
          <div className="flex flex-col justify-center items-center min-h-[140px]">
            <InterviewRecorderActionSection
              isLoading={
                latestTranscriptFragment.save_status ===
                TranscriptFragmentSaveStatusEnum.SAVING
              }
              isRecordingAnswer={isRecordingAnswer}
              onClickStartAnswer={onClickStartAnswer}
              onClickSubmitAnswer={onClickSubmitAnswer}
              mediaRecorder={mediaRecorder}
            />
          </div>
          <div className="flex flex-row space-x-2 items-center text-sm font-semibold text-gray-800 mt-2">
            <InformationCircleIcon className="h-4 w-4 flex-shrink-0" />
            <div>
              Save time by providing detailed responses (a few sentences or
              more) that require fewer clarifying questions. There are no right
              or wrong answers - your honest feedback is really helpful.
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center p-3 w-full border-t border-gray-200 space-x-2">
          <div className="max-w-[300px]">
            {isDesktop && (
              <InterviewMicrophoneControls
                stopAnswering={stopAnswering}
                microphones={microphones}
                setMicrophones={setMicrophones}
                currentMicrophoneDeviceId={currentMicrophoneDeviceId}
                microphoneIdToSwitchTo={microphoneIdToSwitchTo}
                setMicrophoneIdToSwitchTo={setMicrophoneIdToSwitchTo}
                setMicrophoneError={setMicrophoneError}
                isRecordingAnswer={isRecordingAnswer}
              />
            )}
          </div>
          <div className="flex-shrink truncate">
            <Button
              onClick={() => stopAnswering(true)}
              variant={ButtonVariantsEnum.Secondary}
              icon={<ArrowPathIcon className="h-[20px] w-[20px] mr-2" />}
              label="Restart answer"
              isDisabled={!isRecordingAnswer}
            />
          </div>
        </div>
      </div>
    </>
  );
};
