import { Dialog } from "@headlessui/react";
import { Handler } from "app-types";
import { FC } from "react";
import { Button, ButtonVariantsEnum, Link, Modal } from "ui";

interface InterviewTermsModalProps {
  onClickDone: Handler;
  isOpen: boolean;
}

export const InterviewTermsModal: FC<InterviewTermsModalProps> = ({
  onClickDone,
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="flex flex-col items-center">
        <Dialog.Title
          as="h3"
          className="text-lg text-center font-semibold leading-6 text-gray-900"
        >
          Survey terms and conditions
        </Dialog.Title>
        <div className="mt-4 text-xs text-gray-700">
          By taking this survey, you represent, warrant and covenant that:
          <br />
          (a) you will not provide in your responses (or otherwise to any
          principal, employee or affiliate of Alpharun) (i) any information that
          would result in a violation of any applicable laws or any
          confidentiality or similar agreement with any company or entity with
          which you otherwise have a relationship of trust or confidence (ii)
          any material non-public information (whether written or oral);
          <br />
          (b) you will comply with all applicable laws and regulations in each
          jurisdiction in which you are providing survey responses; and
          <br /> (c) this agreement and your performance hereunder will not
          conflict with any other employment or consultancy contract or other
          commitment or violate any other contract, agreement or understanding
          to which you are a party.
        </div>
        <div className="mt-5 sm:mt-6 flex justify-center">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={"Agree and continue"}
            onClick={onClickDone}
          />
        </div>
        <div className="text-sm mt-3 text-gray-600">
          {`Questions? Email us at `}
          <Link href="mailto:research@alpharun.com">research@alpharun.com</Link>
          {"."}
        </div>
      </div>
    </Modal>
  );
};
