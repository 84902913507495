import { Tables, TablesInsert, TablesUpdate } from "../db/supabase.ts";

export enum AttachmentTypesEnum {
  RESUME = "resume",
}

export const allowedResumeMimeTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "application/rtf",
  "text/rtf",
];

export enum AttachmentResourceTypesEnum {
  CONTACT = "contact",
}

export type AttachmentDB = Tables<"attachment">;
export type AttachmentInsert = TablesInsert<"attachment">;
export type AttachmentUpdate = TablesUpdate<"attachment">;

export interface AttachmentMetadata {
  text: string | null;
}

export interface Attachment {
  id: string;
  type: AttachmentTypesEnum;
  company_id: string;
  file_path: string;
  resource_id: string;
  resource_type: AttachmentResourceTypesEnum;
  metadata: AttachmentMetadata;
  created_at: string;
}

export interface AttachmentPublic {
  id: string;
  type: AttachmentTypesEnum;
  file_path: string;
}

export function mapAttachmentDBToAttachment(data: AttachmentDB): Attachment {
  return {
    id: data.id,
    type: data.type as AttachmentTypesEnum,
    company_id: data.company_id,
    created_at: data.created_at,
    file_path: data.file_path,
    resource_id: data.resource_id,
    resource_type: data.resource_type as AttachmentResourceTypesEnum,
    metadata: mapAttachmentMetadataDbToAttachmentMetadata(data.metadata),
  };
}

const attachmentMetadataDefaults: AttachmentMetadata = {
  text: null,
};

const mapAttachmentMetadataDbToAttachmentMetadata = (
  data: any
): AttachmentMetadata => {
  return {
    ...attachmentMetadataDefaults,
    ...(typeof data === "object" ? data : {}),
  };
};

export const attachmentToAttachmentPublic = (
  file: Attachment
): AttachmentPublic => {
  return {
    id: file.id,
    type: file.type,
    file_path: file.file_path,
  };
};
