import { FC } from "react";
import { TextCarouselLoader } from "ui";
import {
  InterviewRecorderCTA,
  InterviewRecorderCTAProps,
} from "./interviewRecorderCTA";

interface InterviewRecorderActionSectionProps
  extends InterviewRecorderCTAProps {
  isLoading: boolean;
}

export const InterviewRecorderActionSection: FC<
  InterviewRecorderActionSectionProps
> = (props) => {
  const { isLoading } = props;

  if (isLoading) {
    return (
      <TextCarouselLoader
        messages={[
          "Securely saving your response...",
          "Analyzing your feedback...",
          "Retrieving next interview question...",
        ]}
      />
    );
  }

  return <InterviewRecorderCTA {...props} />;
};
