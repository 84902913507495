import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  HandlerOf,
  InterviewAgentTypesEnum,
  noop,
  ProjectModesEnum,
  ProjectPublic,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { Button, ButtonVariantsEnum } from "ui";
import { useAppSelector } from "../../hooks/hook";
import { AllowMicrophoneBanner } from "../interviewOnboarding/allowMicrophoneBanner";
import { MicrophoneErrorInstructions } from "../interviewOnboarding/microphoneErrorInstructions";
import { selectProjectLinkState } from "../projectLink/projectLinkSlice";

interface InterviewMicrophoneSetupProps {
  microphones: MediaDeviceInfo[];
  setMicrophones: HandlerOf<MediaDeviceInfo[]>;
  microphoneError: Error | null;
  setMicrophoneError: (error: Error | null) => void;
  micPermission: PermissionState | undefined;
  setMicPermission: HandlerOf<PermissionState | undefined>;
}

/*
 * This component has a few different states:
 * - Microphone setup:
 *   - Audio permissions not yet requested: shows a button to allow permissions
 *   - Asking for audio permissions: shows a banner and loading button (isRequestingMicrophoneAccess)
 *   - Audio permissions rejected (microphoneError)
 * - This component is not shown if microphone access is already granted
 */

export const InterviewMicrophoneSetup: FC<InterviewMicrophoneSetupProps> = ({
  microphoneError,
  setMicrophoneError,
  micPermission,
  setMicPermission,
}) => {
  const { project } = useAppSelector(selectProjectLinkState);

  const [isRequestingMicrophoneAccess, setIsRequestingMicrophoneAccess] =
    useState(false);

  const updatePermissionStatus = async () => {
    const permissionName = "microphone" as PermissionName;
    try {
      const permissionStatus = await navigator.permissions.query({
        name: permissionName,
      });

      setMicPermission(permissionStatus.state);
    } catch (err: any) {
      // TODO: Firefox does not support the permission status API -- optimistically enable
      console.error("Failed to get permission status:", err);
      setMicrophoneError(err);
    }
  };

  const onClickEnableMicrophoneAccess = () => {
    setIsRequestingMicrophoneAccess(true);
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        setIsRequestingMicrophoneAccess(false);

        // Stop the stream since we're just checking permissions
        stream.getTracks().forEach((track) => track.stop());

        updatePermissionStatus();
      })
      .catch((err) => {
        setIsRequestingMicrophoneAccess(false);
        setMicrophoneError(err);

        updatePermissionStatus();
      });
  };

  // Fetch permission status on the initial load of the component
  useEffect(() => {
    updatePermissionStatus();
  }, []);

  const renderContent = () => {
    if (micPermission === "denied" || microphoneError) {
      return <MicrophoneErrorInstructions micPermission={micPermission} />;
    }

    return (
      <>
        <div className="flex flex-row justify-center my-6">
          <Button
            onClick={
              isRequestingMicrophoneAccess
                ? noop
                : onClickEnableMicrophoneAccess
            }
            label={
              isRequestingMicrophoneAccess
                ? "Waiting for microphone access..."
                : "Enable microphone access"
            }
            variant={ButtonVariantsEnum.Primary}
            isLoading={isRequestingMicrophoneAccess}
          />
        </div>
        {renderAudioPermissionsCopy(project)}
      </>
    );
  };

  return (
    <>
      <AllowMicrophoneBanner
        isRequestingMicrophoneAccess={isRequestingMicrophoneAccess}
      />
      {renderContent()}
    </>
  );
};

function renderAudioPermissionsCopy(project: ProjectPublic | null) {
  if (
    project?.mode === ProjectModesEnum.VOICE_AGENT &&
    project?.interview_agent_type ===
      InterviewAgentTypesEnum.RECRUITING_INTERVIEWER
  ) {
    return (
      <div className="text-gray-800 text-sm">
        <div className="mb-4 flex items-center space-x-2">
          <div>
            <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
          </div>
          <span>
            Your interview will be encrypted, securely stored, and never shared
            publicly.
          </span>
        </div>
        <div className="mb-4 flex items-center space-x-2">
          <div>
            <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
          </div>
          <span>
            For the best interview experience, please ensure you're in a quiet
            environment with a stable internet connection.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="text-gray-800 text-sm">
      <div className="mb-4 flex items-center space-x-2">
        <div>
          <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
        </div>
        <span>
          Audio permissions are used to transcribe your answers as you respond
          out loud - it's 5 times faster than typing.
        </span>
      </div>
      <div className="mb-4 flex items-center space-x-2">
        <div>
          <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
        </div>
        <span>
          {
            "All responses are encrypted, securely stored, and never shared publicly."
          }
        </span>
      </div>
      <div className="mb-4 flex items-center space-x-2">
        <div>
          <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
        </div>
        <span>
          You can stop transcribing and restart an answer at any time, so don't
          worry about your responses being perfect.
        </span>
      </div>
    </div>
  );
}
