import { Dialog } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  InterviewAgentTypesEnum,
  InterviewQuestion,
  ProjectModesEnum,
  ProjectPublic,
  ProjectQuestion,
  getInterviewDurationEstimate,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Modal } from "ui";

export const InterviewWelcomePoints: FC<{
  project: ProjectPublic | null;
  isResuming: boolean;
  questions: InterviewQuestion[] | ProjectQuestion[];
}> = ({ project, isResuming, questions }) => {
  const [isQuestionsDialogOpen, setIsQuestionsDialogOpen] = useState(false);

  const remainingQuestionsCount =
    questions.filter((q) => {
      // If a user is resuming their interview, only show remaining questions for the count
      if ("has_been_answered" in q) return q.has_been_answered === false;

      return true;
    }).length || 1; // Default to at least 1 question if the user has answered all primary questions but there may be 1 clarifying question left

  const durationEstimateText = getInterviewDurationEstimate(
    remainingQuestionsCount
  );

  const questionsCountText = `${remainingQuestionsCount} question${
    remainingQuestionsCount > 1 ? "s" : ""
  }`;

  // Welcome message for recruiting mode calls
  if (
    project?.mode === ProjectModesEnum.VOICE_AGENT &&
    project?.interview_agent_type ===
      InterviewAgentTypesEnum.RECRUITING_INTERVIEWER
  ) {
    return (
      <>
        <div className="text-sm text-gray-800">
          <p>
            {project.welcome_message
              ? project.welcome_message
              : "Thanks for your interest in the role and welcome to your fast-track interview. You'll hear from the team shortly after completing this 5 minute call. Please provide your contact details to get started."}
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-sm text-gray-800">
        {project?.welcome_message ? (
          <div className="mb-4">{project.welcome_message}</div>
        ) : null}
        <div className="mb-4 font-bold">
          {isResuming
            ? "You can pick up where you left off:"
            : `Here's what you can expect from this session:`}
        </div>
        <div className="mb-4 flex items-center space-x-2">
          <div>
            <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
          </div>
          <span>
            <span className="font-semibold">
              {project?.incentive_quantity
                ? `$${project?.incentive_quantity} for about ${durationEstimateText}`
                : `Only about ${durationEstimateText}`}
            </span>
            {project?.incentive_quantity
              ? `: we'll email your gift card after you respond to `
              : ": this session has "}
            <span
              className="font-bold underline cursor-pointer hover:text-blue-600"
              onClick={() => setIsQuestionsDialogOpen(true)}
            >
              {"a few questions"}
            </span>
          </span>
        </div>
        <div className="mb-4 flex items-center space-x-2">
          <div>
            <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
          </div>
          <span>
            <span className="font-semibold">Secure and private</span>: your
            responses are encrypted, securely stored, and never shared publicly
          </span>
        </div>
        <div className="mb-4 flex items-center space-x-2">
          <div>
            <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
          </div>
          <span>
            <span className="font-semibold">On your schedule</span>: if you get
            interrupted, you can always pickup where you left off later
          </span>
        </div>
        {project?.welcome_message ? null : (
          <div className="mb-4 flex items-center space-x-2">
            <div>
              <CheckCircleIcon className="h-[20px] w-[20px] text-green-500" />
            </div>
            <span>
              Each month, we send additional $100 gift cards for particularly
              detailed responses
            </span>
          </div>
        )}
      </div>
      {isQuestionsDialogOpen && (
        <Modal
          isOpen={isQuestionsDialogOpen}
          onCancel={() => {
            setIsQuestionsDialogOpen(false);
          }}
        >
          <Dialog.Title
            as="h3"
            className="text-xl text-center font-semibold leading-6 text-gray-900"
          >
            {`This session will take about ${durationEstimateText}`}
          </Dialog.Title>
          <div className="mt-4 mb-2 text-sm">
            <div className="mb-2 text-sm">
              You'll answer the questions below and may see 1 or 2 clarifying
              questions based on your answers.
            </div>
            <ul className="list-disc list-inside ml-2">
              {questions.map((q, i) => (
                <li key={`project-question-${i}`} className={`mb-2`}>
                  {q.question}
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5 flex justify-center">
            <Button
              variant={ButtonVariantsEnum.Primary}
              label={"Got it"}
              onClick={() => {
                setIsQuestionsDialogOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
